import * as React from 'react';
import CrossFilters from '../../lib/CrossFilters';
import Excel from '../../lib/Excel';
import Charts from './Charts';
import Loading from '../custom/Loading';
import { Autocomplete, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, MenuItem, Modal, Paper, Select, TextField } from '@mui/material';
import { Clear, KeyboardArrowDown, KeyboardArrowUp, Search, Settings } from '@mui/icons-material';

const ENABLE_PROCESS_LOG=true;

export class LakeDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            session: null,
            dashboardConfiguration: {},
            crossfilter: null,
            dashboard: null,
            enableBottomFilter: false,
            showBottoms: false,
            filtersCollapsed: true,
            dataLoading: false,
            filterToApplyShown: false
        }
    }

    componentDidMount() {
        this.start();
    }

    name() {
        return this.constructor.name;
    }

    getFilterKeyFromChartId(id) {
        return id;
    }

    clickOnChart(config) {
        let key=this.getFilterKeyFromChartId(config.w.globals.chartID);
        let sel=config.w.config.labels[config.dataPointIndex]; // donut
        if(!sel) {
            sel=config.w.config.xaxis.categories[config.dataPointIndex]; // bar
        }
        let fsel=this.filterLabelToFilterData(key,sel);
        console.log(key, fsel);
        if(key === "yearMonth")
        {
            fsel = this.calcolaMese(fsel);
        }
        this.state.crossfilter.toggleFilter(key,fsel).then(() => {
            this.recompute();
            this.refreshDashboard();
            this.filterChanged(key,sel);
        });
    }

    create_crossfilter() {
        if(ENABLE_PROCESS_LOG) console.info("-- create_crossfilter");
        // eslint-disable-next-line
        this.state.crossfilter=new CrossFilters();

        let db=this.state.dashboard;
        let data=db.data;
        this.state.crossfilter.initFrom(data);

        if(ENABLE_PROCESS_LOG) console.info("-- Calling prepareDimensionsAndGroups");
        this.prepareDimensionsAndGroups();
    }

    recompute() {    
        if(ENABLE_PROCESS_LOG) console.info("-- recompute");
        if(!this.state.crossfilter)
            this.create_crossfilter();
        this.computeChartData();
    }

    refreshDashboard() {
        if(ENABLE_PROCESS_LOG) console.info("-- refreshDashboard");
        let db=this.state.dashboard;
        this.setState({
            dashboard: db,
            showIntro: false
        })
    }

    dataLoaded(data) {
        if(ENABLE_PROCESS_LOG) console.info("-- dataLoaded");
        // eslint-disable-next-line
        this.state.dashboard.data=data;
        // eslint-disable-next-line
        this.state.dataLoading=false;
        this.recompute();
        this.refreshDashboard();
    }

    removeFilter(key,e) {
        let fsel=this.filterLabelToFilterData(key,e);
        this.state.crossfilter.removeFilter(key,fsel).then(() => {
            this.recompute();
            this.refreshDashboard();            
            this.filterChanged(key,e);
        });
    }

    toggleShowBottoms() {
        // eslint-disable-next-line
        this.state.showBottoms=!this.state.showBottoms;
        let temp=this.state.dashboard.data;
        // eslint-disable-next-line
        this.state.dashboard.data=null;
        this.forceUpdate();
        setTimeout(() => 
        {
            // eslint-disable-next-line
            this.state.dashboard.data=temp;
            this.create_crossfilter();
            this.recompute();
            this.refreshDashboard();
        },100);
    }

    fetchDashboard() {
        if(this.state.dataLoading) 
            return;
        this.setState({
            showIntro: false,
            dataLoading: true
        })
        if(ENABLE_PROCESS_LOG) console.info("-- Calling loadData");
        this.loadData();
    }

    start() {
        if(ENABLE_PROCESS_LOG) console.info("-- Start");
        if(ENABLE_PROCESS_LOG) console.info("-- Calling setup");
        this.setup();
        if(ENABLE_PROCESS_LOG) console.info("-- Calling fetchDashboard");
        this.fetchDashboard();
    }

    setup() {
        // HOOK
    }

    filterLabelToFilterData(key,lbl) {
        return lbl; // HOOK
    }

    filterDataToFilterLabel(key,dp) {
        return dp; // HOOK
    }

    prepareDimensionsAndGroups() {        
        // HOOK
    }

    computeChartData() {        
        // HOOK
    }

    loadData() {
        // HOOK
        // alla fine di norma chiama this.dataLoaded(data);
    }

    filterChanged(key,sel) {
        // HOOK
        // Chiamata ogni volta che cambia lo stato dei filtri
    }

    charts() {
        // HOOK
        return null;
    }

    additionalFilters() {
        // HOOK
        return []
    }

    filterGroups() {
        if(this.cached_filterGroups) 
            return this.cached_filterGroups;
        let res=[];
        for(let d in this.state.crossfilter.groups) {
            res.push({
                label: Charts.keyToLabel(d),
                value: d
            })
        }
        if(res.length>0)
            this.cached_filterGroups=res;
        return res;
    }

    groupValues(d) {
        let vs=this.state.crossfilter.groups[d].all();
        let res=[];
        for(let v of vs) {
            res.push({
                label: "" + this.filterDataToFilterLabel(d,v.key),
                value: v.key
            })
        }
        return res;
    }

    toggleFiltersCollapsed() {
        this.setState({
            filtersCollapsed: !this.state.filtersCollapsed
        })
    }

    showFilterSearch(groupPreselection) {
        this.setState({
            filterToApplyShown: true,
            filterToApply: null,
            filterToApplyValue: null
        })
        if(groupPreselection) {
            setTimeout(() => {
                this.setState({
                    filterToApply: groupPreselection
                })
            },50);
        }
    }

    onFilterToApply(e) {
        this.setState({
            filterToApply: e.target,
            filterToApplyValue: null
        })
    }

    hideFilterToApply() {
        this.setState({
            filterToApplyShown: false
        })
    }

    onFilterToApplyValueSelected(e) {
        console.warn("onFilterToApplyValueSelected",e);
        this.setState({
            filterToApplyValue: e.target
        })
    }

    pushFilter(grp,val) {
        // eslint-disable-next-line
        this.state.filterToApply={
            value: grp
        }
        // eslint-disable-next-line
        this.state.filterToApplyValue={
            value: val
        }
        this.applyFilterValue();
    }

    applyFilterValue() {
        let g=this.state.filterToApply;
        let v=this.state.filterToApplyValue;
        console.log("applyFilterValue",g.value,v.value);
        this.state.crossfilter.toggleFilter(g.value,v.value).then(() => {
            // eslint-disable-next-line
            this.state.filterToApplyShown=false;
            this.recompute();
            this.refreshDashboard();
            this.filterChanged(g,v);
        });
    }

    exportTableToExcel(tableID, name) {
        Excel.exportTableToExcel(tableID,name);
    }

    render() {

        if(this.state.dataLoading || !this.state.dashboard || !this.state.dashboard.data) {
            return (
                <Loading message="Creating dashboard..."/>
            );
        }

        let cn=this.props.className;
        if(!cn) cn="dashboard";
        else cn="dashboard " + cn;

        let fs=[];
        for(var dk in this.state.crossfilter.filters) {
            
            let fdim=this.state.crossfilter.activeFilters(dk);
            if(fdim) {
                let selfdk=dk;
                let fselection=fdim.map(e => {
                    let el=this.filterDataToFilterLabel(selfdk,e);
                    return (
                        <Button size="small" variant="contained" color="info" key={e} onClick={() => this.removeFilter(selfdk,e)}>
                            <Clear/> {el}
                        </Button>
                    )
                });
                let lbl=Charts.keyToLabel(dk);
                fs.push(
                    <div className="single-filter" key={dk}>
                        <label className="filter-name">{lbl}</label>{fselection}
                    </div>
                )
            }
        }

        let afs=[];
        afs.push(
            <div className="filter-search" key="_toolbar">
                <Button className="tool-button" variant="contained" color="info" onClick={() => this.showFilterSearch() }>
                    <Search />
                </Button>                    
            </div>
        );
        if(this.state.enableBottomFilter) {
            afs.push(
                <div className="single-filter" key={dk}>
                    <label className="filter-name">Contribution outside top</label>
                    <Checkbox defaultChecked={this.state.showBottoms} onClick={() => this.toggleShowBottoms()} label="Show" />
                </div>);
        }
        afs.push(this.additionalFilters()); 

        let cs=this.charts();

        let filterGroups=[];
        this.filterGroups().forEach(x => {
            filterGroups.push(
                <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
            );
        });

        let filterValues=[];
        if(this.state.filterToApply) {
            this.groupValues(this.state.filterToApply.value).forEach(x => {
                filterValues.push(
                    <MenuItem key={x.value} value={x.value}>{x.label}</MenuItem>
                );
            });
        }
        
        return (            
            <div className={cn}>
                { !this.props.hideFilterWindow &&
                    <Paper className="window shadowed with-outline filters">
                        <div className="window-caption theme-back">
                            <div className="buttons">
                                { !this.state.filtersCollapsed &&
                                    <Button className="btn-custom sys-button" onClick={() => this.toggleFiltersCollapsed() }>   
                                        <KeyboardArrowDown/>                                    
                                    </Button>
                                }
                                { this.state.filtersCollapsed &&
                                    <Button className="btn-custom sys-button" onClick={() => this.toggleFiltersCollapsed() }>        
                                        <KeyboardArrowUp />                               
                                    </Button>
                                }
                            <span className="title">Filters</span>
                            </div>
                        </div>
                        { !this.state.filtersCollapsed &&
                                <div style={{display:'inline-block'}}>
                                    {afs}
                                    {fs}
                                </div> 
                        }
                    </Paper>
                }

                <Dialog open={this.state.filterToApplyShown} maxWidth="md" fullWidth={true} className="filter-dialog">
                    <DialogTitle id="customized-dialog-title">Select filter</DialogTitle>
                    <DialogContent>
                        <Select lagel="Filter" onChange={(s) => this.onFilterToApply(s)}>{filterGroups}</Select>
                        { this.state.filterToApply &&
                            <Select label="Value" onChange={(s) => this.onFilterToApplyValueSelected(s) }>
                                {filterValues}
                            </Select>
                        }
                        
                    </DialogContent>
                    <DialogActions>
                        { this.state.filterToApplyValue &&
                            <Button variant="contained" color="success" onClick={() => this.applyFilterValue()}>Apply</Button>
                        }
                        <Button variant="contained" color="warning" onClick={() => this.hideFilterToApply() }><Clear/> Cancel</Button>
                    </DialogActions>
                </Dialog>
                
                {cs}

                <table id="raw-export" style={{display:'none'}}></table>
            </div>
        );
    }
}
